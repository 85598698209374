*{
  margin: 0;
  padding: 0;
}
body {
  color: #fff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.adm-checkbox-icon {
  border-radius: 2px!important;
}
.adm-checkbox-content {
  font-size: 14px!important;
  font-weight: 600;
  color: #EBF2F8;
  line-height: 20px;
}
.adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
  background: #6490F1!important;
  border-color: #6490F1!important;
}
.adm-button-primary {
  height: 52px!important;
  background: #6490F1!important;
  border-radius: 5px!important;
  font-size: 16px!important;
  font-weight: 600!important;
  color: #FFFFFF!important;
  line-height: 22px!important;
  margin: 0 auto!important;
}
div {
  box-sizing: border-box;
}

.adm-mask {
  background: rgba(0, 0, 0, 0.8)!important;
}
.adm-mask .adm-mask-aria-button {
  background: rgba(0, 0, 0, 0.8)!important;
}

.send-dialog .adm-dialog-body {
  padding: 0!important;
}
.send-dialog .adm-dialog-body .adm-dialog-content {
  padding: 12px!important;
  font-size: 16px!important;
  font-weight: 600!important;
  color: #FFFFFF!important;
  line-height: 22px!important;
}