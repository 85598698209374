.receive {
    text-align: center;
}

.receive canvas {
    margin-top: 98px;
    width: 227px!important;
    height: 227px!important;
}

.receive .label {
    margin-top: 22px;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 500;
    color: #99A4A9;
    line-height: 16px;
}

.receive .currency {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    color: #EFF7F8;
}

.receive .currency img {
    width: 18px;
    margin-right: 7px;
}
.receive .address {
    margin: 27px auto;
    width: 336px;
    height: 47px;
    border: 2px dashed #3E4B57;
    font-size: 16px;
    font-weight: 500;
    color: #EFF7F8;
    line-height: 47px
}

.receive .copy {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 400;
    color: #6490F1;
    line-height: 15px;
}

.receive .copy img {
    margin-right: 7px;
}