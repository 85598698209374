.select-currency {
    text-align: left;
    height: 100%;
    background: #182633;
}

.select-currency div {
    background: #182633;
}

.select-currency .item {
    padding: 13px 0 13px 21px;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    color: #EFF7F8;
}

.select-currency img {
    width: 28px;
    margin-right: 10px;
}