.home {
    text-align: center;
	height: 600px;
	overflow: hidden;
    position: relative;
}
.home .lang {
    width: 27px;
    height: 27px;
    position: absolute;
    top: 22px;
    right: 22px;
}
.home .header {
    background: url('../../assets/images/png/start_bg.png');
    width: 100%;
    height: 314px;
}
.home .title {
    margin-top: 50px;
    font-size: 31px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 44px;
}
.home .sub-title {
    margin-top: 5px;
    margin-bottom: 50px;
    font-size: 13px;
    font-weight: 600;
    color: #9DA3AB;
    line-height: 18px;
}
.home .tip {
    margin-top: 13px;
    font-size: 13px;
    font-weight: 600;
    color: #9DA3AB;
    line-height: 18px;
}
.home .tip span {
    color: #6490F1;
}
.home .adm-button-primary {
    width: 336px!important;
}

.enter-pin .adm-modal-body {
    background: #182633;
}

.enter-pin .adm-modal-body .modal-title {
    font-size: 20px;
    font-weight: 500;
    color: #EFF7F8;
    line-height: 24px;
}

.enter-pin .adm-modal-body .modal-tip {
    font-size: 14px;
    font-weight: 500;
    color: #cc1e1e;
    line-height: 24px;
    margin-bottom: 12px;
}

.enter-pin .adm-modal-body .modal-input {
    margin-top: 36px!important;
    margin-bottom: 33px!important;
    width: 265px!important;
    height: 52px!important;
    background: #0F1820!important;
    border-radius: 6px!important;
}

.enter-pin .adm-modal-body .modal-button {
    width: 125px!important;
    height: 52px!important;
    background: #6490F1!important;
    border-radius: 6px!important;
}

.enter-pin .adm-modal-body .adm-modal-content {
    background: #182633;
}

.enter-pin .adm-modal-body div,  .enter-pin .adm-modal-body span{
    background: #182633;
}

.open-title{
    font-size: 24px;
    color: #6490F1;
}