.terms-of-use {
    padding: 60px 24px 0 24px;
}
.terms-of-use .title {
    font-size: 32px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 34px;
}
.terms-of-use .terms-item {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #6B84DD;
    line-height: 14px;
    width: 100%;
    padding: 0 16px;
    height: 50px;   
    background: #18283F;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.terms-of-use .adm-button-primary img {
    margin-left: 15px;
}
.terms-of-use .adm-checkbox {
    margin-top: 41px;
    margin-bottom: 20px;
}