.select-device {
    padding: 60px 24px 0 24px;
}
.select-device .title {
    font-size: 32px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 34px;
}
.select-device .device {
    margin-top: 24px;
    width: 100%;
    height: 117px;
    background: #18283F;
    border-radius: 6px;
    font-size: 24px;
    font-weight: 600;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 33px;
}