.send {
    text-align: left;
}

.send .adm-form, .send .adm-list, .send .adm-list-body, .send .adm-list-item-content-main, .send .adm-list-item-content {
    border: none!important;
}

.send .adm-form-footer {
    position: absolute;
    width: 100%;
    bottom: 62px;
}

.send .available {
    padding: 0 12px;
    position: absolute;
    width: 100%;
    bottom: 162px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.send .available .balance .label {
    font-size: 16px;
    font-weight: 500;
    color: #EFF7F8;
    line-height: 16px;
}

.send .available .balance .value {
    margin-top: 13px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(239, 247, 248, 0.8);
    line-height: 14px;
}

.send .available .right {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #969BA2;
    line-height: 14px;
}

.send .available .right .adm-switch {
    margin-left: 6px;
}

.send .available .adm-switch-checked .adm-switch-checkbox {
    background: #6490F1;
}

.send .adm-switch-inner {
    display: none;
}