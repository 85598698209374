.set-pin {
    text-align: left;
    background: #21314B;
    min-height: 100vh;
}
.set-pin .set-pin-big-img{
	width: 100%;
	margin-top: -100px;
}
.set-pin .content {
    margin-top: -21px;
    padding: 0 20px;
}

.set-pin div {
    background: #21314B;
}

.set-pin .content .title {
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 26px;
}

.set-pin .content .sub-title {
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 500;
    color: #EFF7F8;
    line-height: 20px;
}

.set-pin .adm-input {
    background: rgba(255, 255, 255, 0.08)!important;
    margin-bottom: 14px;
}

.set-pin .adm-button {
    background: #FFFFFF!important;
    color: #345C81!important;
    margin-top: 14px!important;
}

.set-pin .adm-checkbox {
    margin-top: 27px;
}

.set-pin .tip {
    color: rgba(255, 0, 0, 0.747);
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 500;
}