.recover-mnemonic {
    text-align: left;
    padding: 33px 20px;
}
.recover-mnemonic .adm-text-area {
    height: 165px;
}
.recover-mnemonic .adm-text-area-element {
    height: 165px;
    padding: 10px 20px;
}

.recover-mnemonic .adm-button {
    position: absolute;
    bottom: 63px;
    width: 336px;
}


.recover-mnemonic .info .title{
    font-size: 30px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 28px;
    margin-bottom: 7px;
    margin-top: 19px;
    margin-bottom: 22px;
}

.recover-mnemonic .info .sub-title{
    margin-top: 22px;
    font-size: 14px;
    font-weight: 500;
    color: #EFF7F8;
    line-height: 19px;
}

.recover-mnemonic .adm-checkbox {
    margin-top: 80px;
}