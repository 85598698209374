.account {
    text-align: center;
}

.account .header {
    height: 58px;
    padding: 0 17px;
    background: #182633;
    margin-bottom: 11px;
}

.account .header span {
    font-size: 16px;
    font-weight: 500;
    color: #EFF7F8;
}
.account .balance .left {
   display: flex;
   align-items: center;
}

.account .content {
    padding: 0 8px;
}

.account .balance {
    padding: 0 19px;
    background: #182633;
    border-radius: 6px;
    height: 44px;
}

.account .balance span, .account .balance div,  .account .currency span, .account .currency div {
    background: transparent;
}

.account .balance .left span {
    margin-left: 17px;
    font-size: 16px;
    font-weight: 500;
    color: #EFF7F8;
}

.account .balance .right {
    font-size: 14px;
    font-weight: 600;
    color: rgba(239, 247, 248, 0.6);
}

.account .currency {
    height: 88px;
    background: #182633;
    border-radius: 6px;
    margin-top: 10px;
    padding: 0 19px;
}

.account .currency .left {
    display: flex;
    align-items: center;
 }

.account .currency .left span {
    margin-left: 17px;
    font-size: 16px;
    font-weight: 500;
    color: #EFF7F8;
}

.account .currency .right .u-balance {
    font-size: 14px;
    font-weight: 600;
    color: rgba(239, 247, 248, 0.6);
}

.account .currency .right .currency-balance {
    font-size: 16px;
    font-weight: 500;
    color: #EFF7F8;
    line-height: 22px;
    margin-bottom: 5px;
}

.account .footer-menu {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 88px;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
}
.account-operation .item{
    padding: 20px 33px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #EFF7F8;
    line-height: 22px;
}

.account-operation .item img {
    margin-right: 16px;
}

.account .footer-menu img {
    cursor: pointer;
}

.account .footer-menu .footer-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.account .footer-menu .active {
    color: rgba(95, 138, 234, 1);
}