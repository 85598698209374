.start-wallet {
    text-align: center;
    padding-top: 70px;
}
.start-wallet .title {
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 33px;
}
.start-wallet .sub-title {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #EFF7F8;
    line-height: 20px;
}
.start-wallet .item {
    cursor: pointer;
    margin-top: 26px;
}
.start-wallet .item .label {
    font-size: 18px;
    font-weight: 600;
    color: #6490F1;
    line-height: 25px;
    margin-bottom: 6px;
}
.start-wallet .item .info {
    font-size: 14px;
    font-weight: 400;
    color: #EFF7F8;
    line-height: 20px;
}
.start-wallet img {
	z-index: -1;
    position: absolute;
    max-width: 420px;
    width: 100%;
    bottom: 0;
    left: 0;
}