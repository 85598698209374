.remember-mnemonic {
    text-align: left;
    padding: 33px 20px;
}
.remember-mnemonic .adm-text-area {
    height: 195px;
}
.remember-mnemonic .adm-text-area-element {
    height: 195px;
    padding: 10px 20px;
}

.remember-mnemonic .adm-button {
    position: absolute;
    bottom: 63px;
    width: 336px;
}
.remember-mnemonic .previous {
    position: absolute;
    bottom: 143px;
    width: 336px;
}

.remember-mnemonic .step {
    margin-top: 33px;
    display: flex;
}

.remember-mnemonic .step .info .title{
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 28px;
    margin-bottom: 7px;
}

.remember-mnemonic .step .info .sub-title{
    font-size: 14px;
    font-weight: 500;
    color: #EFF7F8;
    line-height: 19px;
}

.remember-mnemonic .step .num {
    flex: 1 0 25px;
    margin-right: 11px;
    height: 25px;
    line-height: 25px;
    font-weight: 600;
    border-radius: 50%;
    background: #c0d3fe;
    color: #6490F1;
    text-align: center;
}