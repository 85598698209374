.header {
  height: 58px;
  background: #182633;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #EFF7F8;
  display: flex;
  justify-content: space-between;
  padding: 0 18px;
}