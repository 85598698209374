.set-device {
    text-align: center;
    padding: 120px 20px 0 20px;
}
.set-device .title {
    font-size: 24px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 33px;
}
.set-device .sub-title {
    margin-top: 4px;
    margin-bottom: 46px;
    font-size: 14px;
    color: #EFF7F8;
    line-height: 20px;
}
.set-device img {
    position: absolute;
    max-width: 420px;
    width: 100%;
    bottom: 0;
    left: 0;
}